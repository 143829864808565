<script setup>
import { Head, Link } from "@inertiajs/vue3";
import { defineComponent, h } from "vue";

defineProps({
    title: String,
    // canLogin: Boolean,
    canRegister: Boolean,
    // laravelVersion: String,
    // phpVersion: String,
});

import { ref } from "vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";

const navigation = [
    { name: "Kontakti", href: "kontakti" },
    // { name: 'Features', href: '#' },
    // { name: 'Marketplace', href: '#' },
    // { name: 'Company', href: '#' },
];

const mobileMenuOpen = ref(false);

const footerNavigation = {
    main: [
        { name: "Vispārīgie noteikumi", href: "terms-of-service" },
        { name: "Privātuma politika", href: "privacy-policy" },
        { name: "Kontakti", href: "kontakti" },
        { name: "Anotācija", href: "anotacija" },
    ],
    social: [
        {
            name: "Facebook",
            href: "https://www.facebook.com/burnickis/",
            icon: defineComponent({
                render: () =>
                    h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
                        h("path", {
                            "fill-rule": "evenodd",
                            d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
                            "clip-rule": "evenodd",
                        }),
                    ]),
            }),
        },
        {
            name: "Instagram",
            href: "https://www.instagram.com/ronalds.burnickis/",
            icon: defineComponent({
                render: () =>
                    h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
                        h("path", {
                            "fill-rule": "evenodd",
                            d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
                            "clip-rule": "evenodd",
                        }),
                    ]),
            }),
        },
        {
            name: "YouTube",
            href: "https://www.youtube.com/channel/UCxLVx2TEFMfxDEk0zu7MfXw",
            icon: defineComponent({
                render: () =>
                    h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
                        h("path", {
                            "fill-rule": "evenodd",
                            d: "M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z",
                            "clip-rule": "evenodd",
                        }),
                    ]),
            }),
        },
    ],
};
</script>

<style>
.embed-container {
    --video--width: 1296;
    --video--height: 729;

    position: relative;
    padding-bottom: calc(
        var(--video--height) / var(--video--width) * 100%
    ); /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;
    background: black;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>

<template>
    <Head :title="title" />

    <div class="bg-bava-gray">
        <div class="bg-bava-dark py-2 text-white sm:py-3">
            <div class="mx-auto max-w-7xl gap-x-6 px-4 lg:px-8">
                <div class="flex justify-end text-sm">
                    <div class="whitespace-nowrap">
                        <a
                            href="mailto:info@evide.lv?subject=Sveiki%21%20N%C4%81ku%20no%evide.lv%20m%C4%81jaslapas."
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                class="text-bava-primary size-4 inline"
                                fill="currentColor"
                            >
                                <path
                                    stroke="currentColor"
                                    d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
                                />
                            </svg>
                            info@evide.lv
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <header class="bg-white">
            <nav
                class="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
                aria-label="Global"
            >
                <div class="flex lg:flex-1">
                    <a href="/" class="-m-1.5 p-1.5">
                        <ApplicationLogo
                            class="block h-9 w-auto"
                        ></ApplicationLogo>
                    </a>
                </div>
                <div class="hidden lg:flex lg:gap-x-12">
                    <Link
                        v-for="item in navigation"
                        :key="item.name"
                        :href="item.href"
                        class="text-sm font-semibold leading-6 text-gray-900"
                        >{{ item.name }}
                    </Link>
                </div>
                <div class="flex flex-1 items-center justify-end gap-x-6">
                    <Link
                        v-if="$page.props.auth.user"
                        :href="route('dashboard')"
                        class="btn-primary"
                    >
                        Pieslēgties
                    </Link>
                    <template v-else>
                        <Link :href="route('login')" class="btn-primary">
                            Pieslēgties
                        </Link>

                        <Link
                            v-if="canRegister"
                            :href="route('register')"
                            class="btn-primary"
                        >
                            Register
                        </Link>
                    </template>
                </div>
                <div class="flex lg:hidden">
                    <button
                        type="button"
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        @click="mobileMenuOpen = true"
                    >
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </nav>
            <Dialog
                as="div"
                class="lg:hidden"
                @close="mobileMenuOpen = false"
                :open="mobileMenuOpen"
            >
                <div class="fixed inset-0 z-10" />
                <DialogPanel
                    class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                >
                    <div class="flex items-center gap-x-6">
                        <a href="#" class="-m-1.5 p-1.5">
                            <ApplicationLogo
                                class="block h-9 w-auto"
                            ></ApplicationLogo>
                        </a>
                        <Link
                            v-if="$page.props.auth.user"
                            :href="route('dashboard')"
                            class="ml-auto btn-primary"
                        >
                            Pieslēgties
                        </Link>
                        <Link
                            :href="route('login')"
                            v-else
                            class="ml-auto btn-primary"
                        >
                            Pieslēgties
                        </Link>
                        <button
                            type="button"
                            class="-m-2.5 rounded-md p-2.5 text-gray-700"
                            @click="mobileMenuOpen = false"
                        >
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <a
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :href="item.href"
                                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >{{ item.name }}</a
                                >
                            </div>
                            <div class="py-6">
                                <form
                                    v-if="$page.props.auth.user"
                                    method="POST"
                                    @submit.prevent="logout"
                                >
                                    <Link
                                        type="submit"
                                        class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Iziet
                                    </Link>
                                </form>
                                <Link
                                    :href="route('login')"
                                    v-else
                                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Pieslēgties
                                </Link>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>

        <Head :title="title"></Head>
        <div>
            <slot />
        </div>

        <!-- Footer -->
        <div class="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
            <footer class="bg-gray-100 relative border-t border-gray-900/10">
                <div
                    class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8"
                >
                    <nav
                        class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
                        aria-label="Footer"
                    >
                        <div
                            v-for="item in footerNavigation.main"
                            :key="item.name"
                            class="pb-6"
                        >
                            <Link
                                :href="item.href"
                                class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                >{{ item.name }}
                            </Link>
                        </div>
                    </nav>
                    <div class="mt-10 flex justify-center space-x-10">
                        <a
                            v-for="item in footerNavigation.social"
                            :key="item.name"
                            :href="item.href"
                            class="text-gray-400 hover:text-gray-500"
                        >
                            <span class="sr-only">{{ item.name }}</span>
                            <component
                                :is="item.icon"
                                class="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    </div>
                    <p
                        class="mt-10 text-center text-xs leading-5 text-gray-500"
                    >
                        Mūsu rekvizīti:<br />Burnickis SIA, 40203192231, Rīga,
                        Ieroču iela 2 - 1A, LV-1013, Latvija, info@evide.lv
                    </p>
                </div>
            </footer>
        </div>
    </div>
</template>
